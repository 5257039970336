import { PRODUCTS_VIEW_TYPE } from '@/helpers';
import IconClose from '@/components/icons/IconClose.vue';
import ProductsSortSelect from '@/components/products/ProductsBlock/ProductsSortSelect/index.vue';
import ProductsCategoryFilter from '@/components/products/ProductsCategoryFilter/index.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'ProductsSidebarFilters',
  components: {
    ProductsCategoryFilter,
    ProductsSortSelect,
    IconClose
  },
  props: {
    viewType: {
      type: String,
      default: PRODUCTS_VIEW_TYPE.GRID
    },
    isHasFilters: {
      type: Boolean,
      default: false
    }
  },
  emits: ['change-view'],
  data() {
    return {
      isOpenSidebarFilters: false
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      filters: 'selection/filters'
    }),
  },
  methods: {
    onChangeView(type) {
      this.$emit('change-view', type);
    },
    onToggleSidebarFilters() {
      this.isOpenSidebarFilters = !this.isOpenSidebarFilters;
      if (this.isOpenSidebarFilters) {
        document.getElementById('html').classList.add('hide');
      } else {
        document.getElementById('html').classList.remove('hide');
      }
    }
  },
  unmounted() {
    if (this.isOpenSidebarFilters) {
      document.getElementById('html').classList.remove('hide');
    }
  }
};
