import { mapActions, mapGetters, mapMutations } from 'vuex';
import { Flicking, MOVE_TYPE } from '@egjs/vue-flicking';
import '@egjs/vue-flicking/dist/flicking.css';

export default {
  name: 'Banner',
  components: {
    Flicking
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      MOVE_TYPE,
      autoplayInterval: null,
      autoplayIntervalSeconds: 10,
      screenWidth: window.innerWidth
    };
  },
  created() {
    this.startAutoplay();
  },

  computed: {
    ...mapGetters({
      home: 'pages/page'
    })
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({}),
    prevSlide() {
      if (!this.$refs.flicking.animating) {
        this.$refs.flicking.prev();
      }
    },
    nextSlide() {
      if (!this.$refs.flicking.animating) {
        this.$refs.flicking.next();
      }
    },
    startAutoplay() {
      this.autoplayInterval = setInterval(() => {
        if (!this.$refs.flicking.animating) {
          this.$refs.flicking.next();
        }
      }, this.autoplayIntervalSeconds * 1000);
    }
  },
  beforeDestroy() {
    clearInterval(this.autoplayInterval);
  }
};
