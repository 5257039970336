import IconInstagramCard from '@/components/icons/IconInstagramCard.vue';
import { mapGetters } from 'vuex';
import { MEDIA } from '@/helpers/variables';
import ProductsSidebarFilters from '@/components/products/ProductsBlock/ProductsSidebarFilters';

export default {
  name: 'InstaBlock',
  components: { ProductsSidebarFilters, IconInstagramCard },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      MEDIA
    };
  },

  created() {},

  computed: {
    ...mapGetters({
      contacts: 'setting/variables',
      appSize: 'app/size'
    })
  },
  methods: {
    onRedirectInstagram() {
      window.open(this.contacts?.contacts?.instagramSkinon, '_blank');
    }
  }
};
