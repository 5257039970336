import { mapActions, mapGetters, mapMutations } from 'vuex';
import Vue from 'vue';
import { Carousel, Slide } from 'vue-carousel';

//---helpers
import { BLOG_NEWS_TYPE } from '@/helpers';
import { globalLoader } from '@/helpers/variables';

//---mixins
import { dynamicHead } from '@/mixins/dynamic-head.js';

//---components
import Banner from '@/components/custom/Banner/index.vue';
import ProductCard from '@/components/cards/ProductCard/index.vue';
import SeriesCard from '@/components/cards/SeriesCard/index.vue';
import ContentBlockDescription from '@/components/custom/ContentBlock/ContentBlockDescription/index.vue';
import IconArrowPrevLight from '@/components/icons/IconArrowPrevLight.vue';
import IconArrowNextLight from '@/components/icons/IconArrowNextLight.vue';
import IconPlay from '@/components/icons/IconPlay.vue';
import InstaBlock from '@/components/custom/InstaBlock/index.vue';

export default Vue.extend({
  name: 'Home',
  mixins: [dynamicHead],
  components: {
    InstaBlock,
    ContentBlockDescription,
    IconPlay,
    IconArrowNextLight,
    IconArrowPrevLight,
    Banner,
    ProductCard,
    Carousel,
    Slide,
    SeriesCard
  },
  data() {
    return {
      BLOG_NEWS_TYPE,
      videoStatus: false,
      homePage: null,
      homeBanners: [],
      homeSeries: [],
      homeNew: [],
      homeTop: [],
      homeNews: [],
      homeInstagramPosts: []
    };
  },
  created() {
    // window.addEventListener('scroll', this.videoScroll);
    this.showPopups();
    this._loadContent();
  },
  mounted() {},
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      favourites: 'favorites/whichList',
      headerMenu: 'setting/headerMenu',
      contacts: 'setting/variables'
    }),
    seminarsData() {
      return this.homePage?.pageContents?.data?.length ? this.homePage.pageContents.data[0] : null;
    },
    topSellerProducts() {
      if (this.isAuthenticated) {
        if (!this.homeTop) return [];
        if (!this.favourites) return [];
        return this.homeTop.map((e) => {
          const item = this.favourites.find((t) => t.productId === e.id);
          e.selected = !!item;
          return e;
        });
      } else {
        return this.homeTop;
      }
    },

    newProducts() {
      if (this.isAuthenticated) {
        if (!this.homeNew) return [];
        if (!this.favourites) return [];
        return this.homeNew.map((e) => {
          const item = this.favourites.find((t) => t.productId === e.id);
          e.selected = !!item;
          return e;
        });
      } else {
        return this.homeNew;
      }
    }
  },
  methods: {
    ...mapMutations({
      changeRestorePopup: 'popups/CHANGE_RESTORE_POPUP',
      changePaymentInfoPopup: 'popups/CHANGE_PAYMENT_INFO_POPUP',
      resetBasket: 'basket/RESET_BASKET',
      changeLoginPopup: 'popups/CHANGE_LOGIN_POPUP',
      changeSuccessPayment: 'popups/SHOW_SUCCESS_PAYMENT_POPUP'
    }),
    ...mapActions({
      getHomePage: 'home/GET_HOME_PAGE',
      getHomeBanners: 'home/GET_HOME_BANNERS',
      getHomeSeries: 'home/GET_HOME_SERIES',
      getHomeNew: 'home/GET_HOME_NEW',
      getHomeTop: 'home/GET_HOME_TOP',
      getHomeNews: 'home/GET_HOME_NEWS',
      getHomeInstagramPosts: 'home/GET_HOME_INSTAGRAM_POSTS'
    }),

    async _loadContent() {
      globalLoader(true);

      await Promise.all([
        this.getHomePage().then((result) => (this.homePage = result)),
        this.getHomeBanners().then((result) => (this.homeBanners = result)),
        this.getHomeSeries().then((result) => (this.homeSeries = result)),
        this.getHomeNew().then((result) => (this.homeNew = result)),
        this.getHomeTop().then((result) => (this.homeTop = result)),
        this.getHomeNews().then((result) => (this.homeNews = result)),
        this.getHomeInstagramPosts().then((result) => (this.homeInstagramPosts = result))
      ]);

      if (this.homePage && this.homePage.video) {
        this.$refs.videoRef.src = this.homePage.video;
      }

      this._setMetaParams();
      this.setMeta();

      globalLoader(false);
    },
    _setMetaParams() {
      let { metaTitle, metaDescription } = this.homePage;
      if (metaTitle) this.metaParams.title = metaTitle;
      this.metaParams.description = metaDescription;
    },
    showPopups() {
      if (this.$route.query.hasOwnProperty('email') && this.$route.query.hasOwnProperty('token')) {
        this.changeLoginPopup(false);
        this.changeRestorePopup(true);
      }

      if (!this.isAuthenticated) {
        if (this.$route.query.hasOwnProperty('email') && !this.$route.query.hasOwnProperty('token')) {
          this.changeRestorePopup(false);
          this.changeLoginPopup(true);
        }
        if (!this.$route.query.hasOwnProperty('email') && !this.$route.query.hasOwnProperty('token')) {
          this.changeRestorePopup(false);
          // this.changeLoginPopup(true)
        }
      }

      if (this.$route.query.hasOwnProperty('payment-popup')) {
        this.changeSuccessPayment(true, {
          notCall: Number.parseInt(this.$route.query['need-confirm'] || 0) === 1
        });
      }

      if (this.$route.query.hasOwnProperty('payment')) {
        this.changePaymentInfoPopup(true);
        if (this.$route.query.payment === 'success') {
          this.resetBasket();
        } else {
          this.$router.push({ name: 'buy-flow-order' }).catch(() => {});
        }
      }
    },
    videoScroll() {
      if (document.querySelectorAll('video').length > 0) {
        let windowHeight = window.innerHeight,
          videoEl = document.querySelectorAll('video');

        let playButton = document.getElementById('playButton');

        for (var i = 0; i < videoEl.length; i++) {
          var thisVideoEl = videoEl[i],
            videoHeight = thisVideoEl.clientHeight,
            videoClientRect = thisVideoEl.getBoundingClientRect().top;

          if (videoClientRect <= windowHeight - videoHeight * 0.5 && videoClientRect >= 0 - videoHeight * 0.5) {
            this.videoStatus = true;
            playButton.click();
          } else {
            this.videoStatus = false;
            playButton.click();
          }
        }
      }
    },
    onPlayVideo() {
      this.videoStatus = !this.videoStatus;
      this.videoStatus ? this.$refs.videoRef.play() : this.$refs.videoRef.pause();
    },
    onClickTopNav($event, direction) {
      const carousel = this.$refs['homeTopRef'];
      carousel.advancePage(direction);
    },
    onClickNewNav($event, direction) {
      const carousel = this.$refs['homeNewRef'];
      carousel.advancePage(direction);
    }
  }
});
